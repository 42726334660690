import { getFetchConfig } from 'shared/utils';
import {
    CreateCustomer,
    CreateCustomerPayload,
    GetCustomerStatus,
    JoinCustomer,
    JoinCustomerPayload,
    UpdateCustomer,
    UpdateCustomerPayload,
    VerifyPhonePayload,
} from './customers.types';
import { V2_URL } from '../common';

export const updateCustomer: UpdateCustomer = async (customerId, payload) => {
    const config = getFetchConfig<UpdateCustomerPayload>({
        method: 'PATCH',
        payload,
    });
    return fetch(`${V2_URL}/customers/${customerId}/update/`, config);
};

export const joinCustomer: JoinCustomer = async (customerId, payload) => {
    const config = getFetchConfig<JoinCustomerPayload>({
        method: 'POST',
        payload,
    });
    return fetch(`${V2_URL}/customers/${customerId}/join/`, config);
};

export const createCustomer: CreateCustomer = async (payload) => {
    const config = getFetchConfig<CreateCustomerPayload>({
        method: 'POST',
        payload,
    });
    return fetch(`${V2_URL}/customers/create/`, config);
};

export const getCustomerStatus: GetCustomerStatus = async ({
    email,
    merchantId,
    phone_number,
}) => {
    const queryParams = new URLSearchParams({
        merchant_id: merchantId,
    });
    if (email) {
        queryParams.append('email', email);
    }
    if (phone_number) {
        queryParams.append('phone_number', phone_number);
    }
    return fetch(`${V2_URL}/customers/status/?${queryParams.toString()}`);
};

export const fetchVerifyPhone = async (payload: VerifyPhonePayload) => {
    const config = getFetchConfig<VerifyPhonePayload>({
        method: 'POST',
        payload,
    });
    return fetch(`${V2_URL}/customers/phone-verification/verify/`, config);
};

export const fetchSendPhoneVerification = async (phone_number: string) => {
    const config = getFetchConfig({
        payload: { phone_number },
        method: 'POST',
    });
    return fetch(`${V2_URL}/customers/phone-verification/send/`, config);
};

export const fetchMerchantsPhoneNumber = async () => {
    return fetch(`${V2_URL}/customers/phone-verification/phone-number/`);
};
