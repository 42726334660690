export enum ErrorMessages {
    INVALID_INPUT = 'Please provide a valid email address.',
    INVALID_FORM = 'Please make sure that the form is filled out correctly.',
    NETWORK_ISSUE = 'There seems to be a connection issue. Please reload the page and try again.',
    EMAIL_USED = 'This email has already claimed this deal. Please enter a different email address.',
    ALREADY_CLAIMED = 'This deal has already been claimed using this email address.',
    ALREADY_EXPIRED = 'This deal has already been expired.',
    NOT_STARTED = 'This deal has not started yet.',
    DEFAULT = 'Something went wrong. Please try again.',
    PHONE_NUMBER_TAKEN = 'This phone number has already been taken. Please use a different phone number.',
    GENERIC_VALIDATION_MESSAGE = 'This field has an incorrect value.',
    CUSTOMER_NOT_FOUND = "We're sorry, but we could not update your information.",
    FIELD_PHONE_NUMBER = 'Please enter a valid phone number',
    FIELD_BIRTHDAY = 'Please enter a valid birth date.',
    FIELD_FULL_NAME = 'Please enter a valid first and last name.',
}
