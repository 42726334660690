import {
    ErrorResponse,
    InternalErrorResponse,
    TMerchantAddress,
} from 'shared/types';

export enum MerchantType {
    SINGLE = 1,
    ROOT = 2,
    SUBLOCATION = 3,
}

export type TBasicMerchantLocation = {
    address: string;
    merchant_id: string;
};

export type GetMerchantInfoResponse = {
    type: MerchantType;
    address: TMerchantAddress;
    phone_number: string;
    email: string;
    business_name: string;
    primary_color: string;
    secondary_color: string;
    font_color: string;
    font: string;
    show_business_name: boolean;
    logo_url: string;
    locations?: TBasicMerchantLocation[];
    has_loyalty?: boolean;
    has_express?: boolean;
    spots_source: MerchantSpotsSource;
    spots_reward: number;
    purchase_amount: number;
};

export type GetMerchantInfoErrorResponse =
    | ErrorResponse<'merchant_not_found', 'api_exception'>
    | InternalErrorResponse;

export type GetMerchantInfo = (merchant_id: string) => Promise<Response>;

export enum MerchantSpotsSource {
    VISIT = 1,
    SPEND = 2,
}
