import React, { FC } from 'react';

import styles from './LoyaltyJoinScreen.module.scss';

import PresentBox from '../../public/images/presentbox.svg';

import clsx from 'clsx';
import LoyaltyJoinForm, {
    ILoyaltyJoinFormProps,
} from 'components/LoyaltyJoinForm/LoyaltyJoinForm';
import { MerchantSpotsSource } from 'shared/api/v2/merchants/merchants.types';
import { formatCurrency } from 'shared/utils/formatCurrency/formatCurrency';

interface ILoyaltyJoinScreenProps extends ILoyaltyJoinFormProps {
    businessName: string;
    spotsReward: number;
    spotsSource: MerchantSpotsSource;
    spotsMinPurchaseAmount: number;
    isExpressEnabled: boolean;
}

const LoyaltyJoinScreen: FC<ILoyaltyJoinScreenProps> = ({
    businessName,
    spotsReward,
    spotsSource,
    isExpressEnabled,
    spotsMinPurchaseAmount = 1,
    ...rest
}) => {
    const reward = spotsReward ?? '';
    const earnToken = isExpressEnabled ? 'point' : 'spot';
    const forEvery =
        spotsSource === MerchantSpotsSource.SPEND
            ? `for every ${
                  spotsMinPurchaseAmount === 1
                      ? 'dollar'
                      : formatCurrency(spotsMinPurchaseAmount)
              } spent`
            : 'for every check in';

    const description = `Earn ${reward} ${earnToken}${
        reward !== 1 ? 's' : ''
    } ${forEvery} when you join our loyalty program.`;

    return (
        <div className={styles.Wrapper}>
            <section className={styles.FormSection}>
                <h1 className={styles.Title}>{businessName}</h1>
                <h2 className={styles.Subtitle}>Loyalty Program</h2>
                <div className={styles.Content}>
                    <p className={styles.Description}>{description}</p>
                    <LoyaltyJoinForm {...rest} />
                    <p className={styles.TermsAndPolicy}>
                        By proceeding, you agree with{' '}
                        <a href="https://www.spoton.com/legal/">
                            Terms &amp; Conditions
                        </a>{' '}
                        and{' '}
                        <a href="https://www.spoton.com/legal/consumer-privacy/">
                            Privacy Policy
                        </a>{' '}
                    </p>
                </div>
            </section>

            <div
                className={clsx(
                    styles.ThumbnailContainer,
                    styles.ThumbnailScale
                )}
            >
                <PresentBox />
            </div>
        </div>
    );
};

export default LoyaltyJoinScreen;
